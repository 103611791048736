<template>
    <v-container fluid>
        <v-content>
            <h3 v-for="num of products">{{num}}</h3>
        </v-content>
    </v-container>
</template>

<script>
    export default {
        name: "Store",
        data: () => ({
            products: [],
            repeat: Array(1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,2,3,5,3,4,7,7,3),
            title: "Store"
        }),
        created () {
            this.$http.get(this.$http.defaults.baseURL + '/store/products')
                .then( categories_data => {
                    //console.log(categories_data);
                    this.products = categories_data.data.results;
                });
        }
    }
</script>

<style scoped>

</style>